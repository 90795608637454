import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Container, Divider, Grid, Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CollectionProps } from 'sensortower-components/src/base-components/Collection';
import { CardProps } from 'sensortower-components/src/base-components/Card';
import Text, { TextProps } from 'sensortower-components/src/base-components/Text';
import ContentModule from 'sensortower-components/src/base-components/ContentModule';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import { client, parseBooleanEnvVar } from 'sensortower-utils';
import useSwr from 'swr';
import range from 'lodash/range';

import Filters from '../Filters';
import Pagination from '../Pagination';
import { Option } from '../Filter';
import { useLocalizationContext } from '../LocalizationContext';
import { sidekick } from '../../utils/sidekick';
import usePrevious from '../../utils/usePrevious';

const preview = parseBooleanEnvVar(process.env.CONTENTFUL_USE_PREVIEW);

interface FilterOptions {
  [key: string]: Option[];
}

interface SettingsFilter {
  id: string;
  label: string;
  multiple: boolean;
  hidden?: boolean;
}

export interface Settings {
  filters: SettingsFilter[];
}

export interface CollectionFilteredProps extends Omit<CollectionProps, 'id' | '__typename'> {
  __typename?: string;
  id?: string;
  introText?: TextProps;
  itemsVariant?: string;
  itemsConnection?: { items?: CardProps[]; total?: number };
  filterOptions?: FilterOptions;
  numberOfItems?: number;
  settings?: Settings;
  sidekickLookup?: any;
}

export interface Query {
  [key: string]: string | string[] | undefined;
}

const defaultOrder = '-fields.pubDate';

export const CollectionFiltered = ({
  sidekickLookup,
  filterOptions,
  numberOfItems,
  itemsConnection,
  ...props
}: CollectionFilteredProps) => {
  const [filter, setFilter] = useState<any>({});
  const [limit] = useState(numberOfItems ?? 4);
  const [offset, setOffset] = useState<number | undefined>();
  const router = useRouter();
  const { page, slug: _slug, ...query } = router.query;
  const prevQuery: Query = usePrevious<Query>(query);
  const localization = useLocalizationContext();

  const fetchItems = async (filterRaw: Query, limit?: number, offset?: number, locale?: string) => {
    try {
      const filterOptions = { ...filterRaw };
      // REMOVE QUERY PARAMS RELATED TO PREVIEW
      delete filterOptions.environment;
      delete filterOptions.id;
      delete filterOptions.locale;

      if (offset === 0 && !Object.keys(filterOptions).length) return itemsConnection;
      if (!filterOptions.order) filterOptions.order = defaultOrder;

      const { data } = await client.CollectionItems({
        locale,
        id: props.id ?? '',
        limit,
        offset,
        filter: filterOptions,
        preview,
      });

      if (data?.content?.__typename == 'Collection') {
        const items = data?.content?.itemsConnection?.items;
        const total = data?.content?.itemsConnection?.total;
        const options = data?.content?.itemsConnection?.pageInfo?.options;

        return {
          items,
          options,
          total,
        };
      }
      return null;
    } catch (err) {
      return itemsConnection;
    }
  };
  const { data } = useSwr(offset !== undefined ? [filter, limit, offset, router.locale] : null, fetchItems);
  const pages = Math.ceil((data?.total || 0) / limit || 0);
  const allFilters = props?.settings?.filters.filter((f) => f.id !== 'order') ?? [];
  const visibleFilters = allFilters?.filter((f) => !f.hidden);

  const handleClearSearch = () => {
    delete router.query.query;
    router.push(router, undefined, { shallow: true });
  };

  useEffect(() => {
    const newOffset = page ? (Number(page) - 1) * limit : 0;
    setOffset(newOffset);
  }, [page]);

  useEffect(() => {
    if (JSON.stringify(prevQuery ?? {}) !== JSON.stringify(query)) {
      setFilter(query);
    }
  }, [prevQuery, query]);

  return (
    <ErrorBoundary>
      <Root
        maxWidth="xl"
        id="search"
        data-testid="CollectionFiltered"
        {...sidekick(sidekickLookup)}
        sx={props.styles?.root}
      >
        {props.introText && (
          <>
            {!filter.query || filter.query?.trim() === '' ? (
              <Text {...props.introText} />
            ) : (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                pb={{ xs: 2, md: 4 }}
                data-testid="CollectionFiltered-introText"
              >
                <Grid item>
                  <Typography variant="h2" color="primary" sx={{ flex: 1 }}>
                    {localization['common.searchingLabel']?.shortTextValue ?? 'Searching:'} {filter?.query?.trim()}
                  </Typography>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleClearSearch}
                    sx={{ height: 40, fontSize: 14, mb: { xs: 0 } }}
                  >
                    {localization['common.clearSearchLabel']?.shortTextValue ?? 'Clear Search'}
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {visibleFilters.length > 0 ? (
          <Filters
            filters={
              allFilters.map((o) => ({
                ...o,
                label: localization[`common.filter${o.label}Label`]?.shortTextValue ?? o.label,
                variant: 'outlined',
                items: (filterOptions && filterOptions[o.id]) || [],
              })) || []
            }
            sort={{
              id: 'order',
              variant: 'outlined',
              label: props.settings?.filters?.find((o) => o.id === 'order')?.label || 'Sort',
              defaultSelection: [defaultOrder],
              items: [
                {
                  value: 'fields.pubDate',
                  label: localization['common.dateAscendingLabel']?.shortTextValue ?? 'Date Ascending',
                },
                {
                  value: '-fields.pubDate',
                  label: localization['common.dateDescendingLabel']?.shortTextValue ?? 'Date Descending',
                },
              ],
            }}
          />
        ) : null}

        <Grid container spacing={4}>
          <Grid container item xs={12} spacing={4}>
            {/* Cards */}
            {data &&
              data?.items?.map((card, index) => (
                // 1 column on small screens, 2 on medium, 3 on large
                <Grid key={card?.id} item xs={12} md={6} lg={4}>
                  <ContentModule {...card} variant={props.itemsVariant ?? (card as CardProps)?.variant} />

                  {index + 1 < (data?.items?.length ?? 0) && (
                    // Show divider only in 1 column layout
                    <Divider
                      sx={{
                        display: { xs: 'block', md: 'none' },
                        mt: 4,
                        mb: 0,
                        width: ['100%', '95%'],
                        borderColor: 'primary.main',
                      }}
                    />
                  )}
                </Grid>
              ))}

            {/* Card skeletons while loading entries */}
            {!data &&
              range(limit).map((_: any, index: number) => (
                <Grid key={`loading-card-${index}`} item xs={12} md={4}>
                  <ContentModule __typename={'Card'} variant={props.itemsVariant} loading />

                  {index + 1 < (limit ?? 0) && (
                    // Show divider only in 1 column layout
                    <Divider
                      sx={{
                        display: { xs: 'block', md: 'none' },
                        mt: 4,
                        mb: 0,
                        borderColor: 'primary.main',
                        width: ['100%', '95%'],
                      }}
                    />
                  )}
                </Grid>
              ))}
          </Grid>

          {/* Pagination on small screen */}
          <Grid item xs={12} sx={{ display: ['block', 'none'] }}>
            <Box my={2}>
              <Pagination count={pages} size="small" />
            </Box>
          </Grid>
        </Grid>

        {/* Pagination on larger screens */}
        <Box sx={{ display: ['none', 'block'], mt: 8, mb: 2 }}>
          <Pagination count={pages} />
        </Box>
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(Container, {
  name: 'CollectionFiltered',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any),
  }),
})(({ theme }) => ({
  paddingTop: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  '& h2': {
    '&.MuiTypography-root': {
      color: theme.palette.secondary.contrastText,
    },
  },
  '& [class*="MuiGrid-container"] [class*="MuiGrid-container"]': {
    '& [class*="Card-root"]': {
      [theme.breakpoints.down('md')]: {
        '& h3': {
          marginTop: theme.spacing(2),
        },
      },
    },
  },
  '& h3': {
    marginBottom: theme.spacing(1),
  },
  '& [class*="MuiGrid-container"] [class*="MuiDivider-root"]': {
    width: 'calc(100% - 16px)',
  },
  '& [class*="MuiPaginationItem-root"]': {
    fontWeight: 200,
    '&[class*="Mui-selected"]': {
      borderRadius: 10,
    },
  },
  '& [class*="MuiPaginationItem-previousNext"]': {
    fontSize: 19,
    fontWeight: 600,
    '& svg': {
      fontSize: 25,
    },
  },
}));

export default CollectionFiltered;
