import React from 'react';
import { SectionProps } from 'sensortower-components/src/base-components/Section';
import { CollectionProps } from 'sensortower-components/src/base-components/Collection';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';

import CollectionFiltered, { Settings } from '../CollectionFiltered';

interface Collection extends CollectionProps {
  settings: Settings;
}

export interface SectionLatestArticlesProps extends SectionProps {
  collection?: Collection;
}

export const SectionLatestArticles = ({ collection }: SectionLatestArticlesProps) => {
  return (
    <ErrorBoundary>
      <CollectionFiltered {...collection} />
    </ErrorBoundary>
  );
};

export default SectionLatestArticles;
